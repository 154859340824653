<template>
  <v-app>
    <v-container bg fluid fill-height grid-list-md  v-if="formData">
      <v-layout justify-center row wrap align-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-card-text>
              <div class="login-logo col-12 pa-0 ma-0 text-center">
                <img src="/logo.png" width="100" class="hidden-sm-and-down" />
                <span>EDOLMED</span>
              </div>

              <div class="mt-6 login-logo col-12 pa-0 ma-0 text-center">
                <h5>
                  Olá {{ formData.usuario.strNome }}, aqui você pode criar sua nova senha
                  de acesso.
                </h5>
              </div>

              <ed-form
                :recaptcha="$root.$session.isProduction"
                :formData="formData"
                :handlerSave="salvar"
                :iconButton="$utilidade.getIcone('restaurar')"
                labelButton="Alterar Senha"
              >
                <!-- <ed-input-text
                  v-model="formData.usuario.strLogin"
                  name="strLogin"
                  label="Login"
                  :icon-left="$utilidade.getIcone('usuario')"
                  rules="required"
                  disabled
                /> -->

                <ed-input-text
                  v-model="formData.strNovaSenha"
                  name="strNovaSenha"
                  label="Nova Senha"
                  type="password"
                  :icon-left="$utilidade.getIcone('senha')"
                  rules="required|senha"
                  info="A senha deve conter 6 caracteres ou mais, sendo formado por letras e números."
                />
                <ed-input-text
                  v-model="formData.strConfirmarNovaSenha"
                  name="strConfirmarNovaSenha"
                  label="Repita sua Nova Senha"
                  type="password"
                  :icon-left="$utilidade.getIcone('senha')"
                  rules="required|senha"
                  info="A senha deve conter 6 caracteres ou mais, sendo formado por letras e números."
                />
              </ed-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { EdInputText, EdAlert, EdButton, EdForm } from "@/components/common/form";

export default {
  name: "pageIndex",
  props: {},
  components: { EdForm, EdAlert, EdInputText, EdButton },
  mounted() {
    this.getUsuario();
  },
  created() {},
  data() {
    return {
      formData: null,
    };
  },
  methods: {
    getUsuario() {
      if (!this.$route.params.strToken) {
        this.bind.strError = "Link de acesso não encontrado ou é inválido";
        return;
      }

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("publico/usuario-senha/dados", {
          strToken: this.$route.params.strToken,
          boolDadosUsuario: true,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            this.formData = Object.assign(objResponse.result, {});
          } else {
            this.$root.$message.error(objResponse.msg);
            this.$router.push({
              name: "loginCliente",
            });
          }
        });
    },

    salvar() {
      if (this.formData.strConfirmarNovaSenha != this.formData.strNovaSenha) {
        this.$root.$dialog.error(
          "O campo `Nova Senha` e `Repita sua Nova Senha` devem ser iguais"
        );
        return;
      }
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("publico/usuario-senha/alterar", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            this.$root.$message.success("Senha alterada com sucesso");
            this.$router.push({
              name: "loginCliente",
              query: { strLogin: this.formData.usuario.strLogin },
            });
          } else {
            this.$root.$dialog.error(objResponse.msg);
          }
        });
    },
  },
};
</script>
